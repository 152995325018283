import React, { useEffect, useMemo, useRef, useState } from "react"
import { RewardsDashboardTopSection, SecondComponentMainDiv, TopComponentMainDiv } from "./styles"
import { connect } from "react-redux";
import { fetchApi } from "../../utils/methods";
import { GetAllRewardsDocumentsForCompany, /*GetCompanyNameToUpdateRewardTire, GetCompanyRewardTier,*/ GetCompanyRewardTiers, GetRewardsReport } from "../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import CompanyMedalCardsDashboard from "./components/CompanyMedalCardsDashboard";
import PropTypes from 'prop-types'
import { setSelectedYear } from "../../redux/actions";
import Loading from "../Loading";
import DocumentDashboard from "./components/DocumentDashboard";
import {  RewardsPointDashboardDownloadIcon, RewardsPointsDashboardManageRewardsIcon } from "../../utils/icons";
import CustomPopupNewUI from "./components/CustomPopupNewUI";
import ManageRewardsBucketCapsForm from "./components/ManageRewardsBucketCapsForm";
import HoverButton from "../common/HoverButton";
import YearDropdown from "../common/YearDropdown/YearDropdown";
import CSVList from "../PeopleHomeV2/CSVList";

function RewardsManagementDashboard({selectedYearInfo, yearList, setSelectedYear, companyInfo, /*role,*/history}) {

  const [companyRewardDetails, setCompanyRewardDetails] = useState(null);
  const [yearMenuIsOpen, setYearMenuIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rewardsDocumentsData, setRewardsDocumentsData] = useState([]);
  const [showCustomPopup/* , setShowCustomPopup */] = useState(false);
  const [showManageRewardsBucketCapsForm, setShowManageRewardsBucketCapsForm] = useState(false);

  const [isActive, setIsActive] = useState('overview');

  const dropdownRef = useRef();
  const[printRewardCSV, setprintRewardCSV] = useState(false);
  const [rewardReportData, setRewardReportData] = useState([]);


  const updateRewardsCardsData = (value) => {
    setCompanyRewardDetails(value);
  }



  // To Fetch All Rewards Documents for a selected company
  const getAllDocumentsForCompany = async (comp_id) => {
    const allDocuments = await fetchApi(`${GetAllRewardsDocumentsForCompany}/${comp_id}`, "GET");
    setRewardsDocumentsData(allDocuments.data);
  }

  const memoizeDocumentsData = useMemo( () => rewardsDocumentsData, [rewardsDocumentsData] );

  const toggleYearMenu = () => {
    setYearMenuIsOpen((prevyearMenuIsOpen) => !prevyearMenuIsOpen);
  };


  useEffect(()=>{
    fetchCompanyRewardsDetailsYearwise(companyInfo.id, selectedYearInfo, true);
  }, []);


  const refreshDocumentDashboard = (company_id) => {
    getAllDocumentsForCompany(company_id);
  }

  const fetchCompanyRewardsDetailsYearwise = async(companyId, year, isCallRewardApi) =>{
    setLoading(true);
    const companyRewardsData = await fetchApi(GetCompanyRewardTiers+'/'+companyId + (year && `/${year}`) , "GET",null,{}, successFunc);
    if(companyRewardsData.message){
      toast.error(companyRewardsData.message);
      return;
    }
    updateRewardsCardsData(companyRewardsData.data);
    isCallRewardApi && getAllDocumentsForCompany(companyId);
  }

  const getRewardReport = async(companyId, year)=>{
    const companyRewardsReport = await fetchApi(GetRewardsReport+'/'+companyId + (year && `/${year}`) , "GET");
    setRewardReportData(companyRewardsReport.data);
    if(companyRewardsReport.data.length === 0){
      toast.error("No data found");
      return;
    }
    // getCSVHeaders(companyRewardsReport.data[0]);
    if(companyRewardsReport.message){
      toast.error(companyRewardsReport.message);
      return;
    }else{
      setprintRewardCSV(false);

    }
  }

  useEffect(()=>{
    if(rewardReportData.length > 0){
      setprintRewardCSV(true);
    }
  },[rewardReportData]);

  const getCSVHeaders = () => {
    let object = rewardReportData[0];
    let keysArray = object && Object.keys(object);
    
    // Extract the values for "Finished", "Gold", "Silver", and "Bronze"
    const finished = keysArray && keysArray.find((key) => key.includes("Finished"));
    const gold = keysArray && keysArray.find((key) => key.includes("Gold"));
    const silver = keysArray && keysArray.find((key) => key.includes("Silver"));
    const bronze = keysArray && keysArray.find((key) => key.includes("Bronze"));
  
    const csvHeaders = [
      { label: "User ID", key: "uid" },
      { label: "First Name", key: "FIRST NAME" },
      { label: "Last Name", key: "LAST NAME" },
      { label: "Email", key: "EMAIL" },
      { label: "Department", key: "DEPARTMENT" },
      { label: "Location", key: "LOCATION" },
      { label: "Team", key: "TEAM NAME" },
    ];
  
    if (bronze) {
      csvHeaders.push({ label: bronze, key: bronze });
    }
    if (silver) {
      csvHeaders.push({ label: silver, key: silver });
    }
    if (gold) {
      csvHeaders.push({ label: gold, key: gold });
    }
    if (finished) {
      csvHeaders.push({ label: finished, key: finished });
    }
  
    return csvHeaders;
  };

  

  const successFunc = ()=>{
    setLoading(false);
  }

  const updateDropdown = (year) => {
    setSelectedYear(year);
    fetchCompanyRewardsDetailsYearwise(companyInfo.id, year, false);
  };

  const printAction = (bool) => {
    setprintRewardCSV(bool)
  }

  const hideManageRewardsBucketCapsForm = ()=>{
    setShowManageRewardsBucketCapsForm(false);
    fetchCompanyRewardsDetailsYearwise(companyInfo.id, selectedYearInfo, true);

  }


  return (
    <>
      <RewardsDashboardTopSection>
        {
          showManageRewardsBucketCapsForm 
            ? <ManageRewardsBucketCapsForm setShowManageRewardsBucketCapsForm={setShowManageRewardsBucketCapsForm} hideManageRewardsBucketCapsForm={hideManageRewardsBucketCapsForm} companyRewardDetails={companyRewardDetails?.company_tier} companyInfo={companyInfo} history={history}/>
            :
            <>
              <TopComponentMainDiv>
                <div className="navigationTabs">
                  <div className={"navTab "+(isActive == 'overview' && "activeTab")} onClick={()=> { setIsActive('overview'); }}>
                    Rewards
                  </div>
                  <div className={"navTab "+(isActive == 'documents' && "activeTab")} onClick={()=>{ setIsActive('documents'); }}>
                    Documents
                  </div>
                </div>

                <div className="pointsActions">

                  <YearDropdown
                    refOutside={dropdownRef}
                    toggleMenu={toggleYearMenu}
                    background={"white"}
                    title={
                      selectedYearInfo && (
                        <div
                          className="title"
                          style={{
                            display: "flex",
                            justifyContent: yearList?.length > 1 ? "space-between" : "center",
                            width: "100%",
                            fontFamily: "Rubik-Medium",
                            color: "#007AB1",
                            fontSize: "16px",
                            lineHeight: "20px",
   
                          }}
                        >
                          <div>{selectedYearInfo}</div>
                          {yearList?.length > 1 && (<div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "16px",
                            }}
                          >
                            <svg
                              width="10"
                              height="16"
                              viewBox="0 0 10 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                transform: yearMenuIsOpen ? "rotate(90deg)" : "rotate(90deg)",
                              }}
                            >
                              <path
                                d="M10 8.00003C10 8.28678 9.87995 8.5735 9.64036 8.79212L2.09719 15.6718C1.61736 16.1094 0.839379 16.1094 0.359734 15.6718C-0.119911 15.2343 -0.119911 14.5249 0.359734 14.0872L7.03435 8.00003L0.359967 1.91279C-0.119678 1.47515 -0.119678 0.765811 0.359967 0.328387C0.839612 -0.109461 1.61759 -0.109461 2.09743 0.328387L9.6406 7.20793C9.88023 7.42666 10 7.71338 10 8.00003Z"
                                fill="#007AB1"
                              />
                            </svg>
                          </div>)}
                        </div>
                      )
                    }
                    data={yearList}
                    onSelect={updateDropdown}
                    style={{
                      background: "white",
                      lineHeight: "20px",
                      color: "rgba(0, 92, 135, 0.6)",
                      padding: "0px",
                      border: "1px solid #005C874D",
                      marginRight:"6px",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                  />


                  {(selectedYearInfo === 2024 || selectedYearInfo === "2024") && <HoverButton svgPath={RewardsPointDashboardDownloadIcon()} activeColor={"#007AB1"} fillOpacity={1} title={"Download"} onClick={()=>getRewardReport(companyInfo.id, selectedYearInfo)}/>}

                  <div style={{marginRight:"10px"}}> <HoverButton svgPath={RewardsPointsDashboardManageRewardsIcon()} activeColor={"#007AB1"} fillOpacity={companyRewardDetails?.is_tier_updated ? 0.6 : 1} title={"Manage Rewards"} onClick={()=> !companyRewardDetails?.is_tier_updated && setShowManageRewardsBucketCapsForm(true)} disabled={companyRewardDetails?.is_tier_updated ? true : false} />
                  </div>
                  
                </div>
              </TopComponentMainDiv>

              <SecondComponentMainDiv>
                {
                  isActive === 'overview' 
                    ?
                    loading ? <Loading/> : companyRewardDetails && <CompanyMedalCardsDashboard companyRewardDetails={companyRewardDetails?.company_tier} />
                    :
                    isActive === 'documents' 
                  &&
                  <DocumentDashboard documentsData={memoizeDocumentsData} refreshDocumentDashboard={refreshDocumentDashboard} companyInfo={companyInfo}/>
                }
              </SecondComponentMainDiv>

              { showCustomPopup && <CustomPopupNewUI  />}
            </>
        }
        {printRewardCSV && <CSVList Title={`${"Rewards Report"}_${companyInfo.name}`} removeCSVData={printAction} headers={getCSVHeaders()} data={rewardReportData} />}
      </RewardsDashboardTopSection>
    </>
  )
}

RewardsManagementDashboard.propTypes = {
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYear:PropTypes.func,
  companyInfo:PropTypes.object,
  role:PropTypes.string,
  history:PropTypes.object,
}

const mapStateToProps = (state) => ({
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsManagementDashboard);